.row * {
  vertical-align: middle;
}

.ant-slider {
  display: inline-block;
  width: 240px;
}

.hidden {
  display: none
}
